import { gql, useMutation } from '@apollo/client';
import middlewareClient from '../apollo/middlewareClient';

const useUpdateCustomerProfile = () => {
  const UPDATE_CUSTOMER_PROFILE = gql`
    mutation UpdateCustomerProfile($input: UpdateCustomerInput!) {
      updateCustomer(input: $input) {
        hpidProfile {
          id
          meta {
            resourceType
            created
            lastModified
            version
            location
          }
          schemas
          countryResidence
          emails {
            primary
            type
            value
            verified
            accountRecovery
          }
          enabled
          extendedMeta {
            createdByClient
            lastModifiedByClient
            lastModifiedByUser
          }
          legalZone
          locale
          name {
            givenName
            middleName
            familyName
          }
          phoneNumbers {
            accountRecovery
            number
            primary
            type
            verified
            id
          }
          type
          userName
        }
      }
    }
  `;

  return useMutation(UPDATE_CUSTOMER_PROFILE, {
    client: middlewareClient,
  });
};

export default useUpdateCustomerProfile;
