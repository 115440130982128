import React from 'react';

const noNegativeNumbers = (e: React.KeyboardEvent) => {
  if (
    e.key === 'Minus' ||
    e.key === '-' ||
    e.key === 'ArrowUp' ||
    e.key === 'ArrowDown'
  )
    e.preventDefault();
};

export default noNegativeNumbers;
