import * as React from 'react';
import {
  Grid,
  GridProps,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CustomCaption from './CustomCaption';

export type SummaryRowProps = {
  leftTitle?: string;
  centerTitle?: string;
  rightTitle?: string;
  left?: number | string;
  center?: number | string;
  right?: number | string;
} & GridProps;

const SummaryRow: React.FC<GridProps & SummaryRowProps> = ({
  leftTitle,
  left,
  centerTitle,
  center,
  rightTitle,
  right,
  sx,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      flexDirection="row"
      columns={12}
      columnSpacing={isMobile ? 3 : 4}
      rowSpacing={3}
      sx={{ ...sx }}
    >
      <Grid item>
        {left && (
          <Stack>
            <CustomCaption text={leftTitle} />
            <Typography variant="body2">{left}</Typography>
          </Stack>
        )}
      </Grid>
      <Grid item>
        {center && (
          <Stack>
            <CustomCaption text={centerTitle} />
            <Typography variant="body2">{center}</Typography>
          </Stack>
        )}
      </Grid>
      <Grid item>
        {right && (
          <Stack>
            <CustomCaption text={rightTitle} />
            <Typography variant="body2">{right}</Typography>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default SummaryRow;
