const showCloseButton = (inputType: string | undefined): boolean => {
  if (inputType !== undefined && inputType !== null) {
    if (inputType.length > 0) {
      return true;
    }
  }
  return false;
};

export default showCloseButton;
