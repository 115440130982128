import React from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StyledGrid } from './AddressSection';
import Button from '../button';
import { CustomHeading, AccountProfileProps } from './ProfileSection';
import { HPID_SECURITY_TAB_URL } from '../../settings';
import StaticTextContainer from './StaticTextContainer';
import SuccessCheckmark from '../icon/success-checkmark';
import { Icon, IconName } from '../icon';

const VerifiedContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.625rem;
`;

const Capitalized = styled('span')`
  text-transform: capitalize;
`;

const LoginDetails: React.FC<AccountProfileProps> = ({ user }) => {
  const largeScreen = useMediaQuery('(min-width:1240px)');
  const currentUser = user?.profile;
  const theme = useTheme();

  const handleOnClick = () => {
    if (currentUser) {
      navigate(HPID_SECURITY_TAB_URL);
    } else {
      navigate('/');
    }
  };

  const { content } = useStaticQuery(graphql`
    query ProfileLoginTitles {
      content: graphCmsAccountProfile05 {
        titleLoginDetails
        ctaLoginDetails
      }
    }
  `);

  return (
    <>
      <StyledGrid item xs={12} marginBottom={6}>
        <CustomHeading>
          {content?.titleLoginDetails ?? 'Login Details'}
        </CustomHeading>
        {user?.profile?.identityProvider !== 'hpid' && (
          <Grid
            flexWrap="nowrap"
            container
            sx={{ marginTop: theme.spacing(5) }}
          >
            <Grid item>
              <Icon
                iconName={user?.profile?.identityProvider as IconName}
                sx={{ marginRight: '1rem' }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="bodyM"
                sx={{ color: theme.palette.greyscale['05'] }}
              >
                Your login information is currently managed by your
                <Capitalized>{` ${user?.profile?.identityProvider} `}</Capitalized>{' '}
                account. <br />
                To make any changes, please go there.
              </Typography>
            </Grid>
          </Grid>
        )}
      </StyledGrid>
      <StyledGrid item xs={4} sm={4} md={4} lg={4} marginBottom={5}>
        <StaticTextContainer
          label="Email address"
          value={currentUser?.emails?.[0]?.value || ''}
        />
        {currentUser?.emails?.[0]?.verified && (
          <VerifiedContainer>
            <SuccessCheckmark />
            <Typography variant="body2" color="#60DF6A">
              Verified
            </Typography>
          </VerifiedContainer>
        )}
      </StyledGrid>
      <StyledGrid
        container
        item
        xs={1}
        sm={1}
        md={1}
        lg={1}
        marginBottom={5}
        sx={{
          '.MuiFormControl-root': {
            flexDirection: 'row',
            justifyContent: 'center',
          },
        }}
      >
        {currentUser?.addresses?.[0]?.phone && (
          <>
            <StaticTextContainer
              label="Phone number"
              value={currentUser?.addresses?.[0].phone}
            />
            <VerifiedContainer>
              <SuccessCheckmark />
              <Typography variant="body2" color="#60DF6A">
                Verified
              </Typography>
            </VerifiedContainer>
          </>
        )}
      </StyledGrid>
      {largeScreen && (
        <StyledGrid item lg={4} marginBottom={5}>
          <div />
        </StyledGrid>
      )}
      <StyledGrid item xs={4} sm={4} md={4} lg={4} marginBottom={0}>
        {user?.profile?.identityProvider === 'hpid' && (
          <Button
            variant="outline"
            text={content?.ctaLoginDetails ?? 'Edit login details'}
            onClick={() => handleOnClick()}
            color="cyan"
          />
        )}
      </StyledGrid>
    </>
  );
};

export default LoginDetails;
