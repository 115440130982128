import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import theme from '../../theme';

interface StaticTextProps {
  label: string;
  value: string;
}

const StyledBox = styled(Box)`
  min-width: 17.5rem;
`;

const StaticTextContainer: React.FC<StaticTextProps> = ({ label, value }) => (
  <StyledBox>
    <Typography
      variant="caption"
      color={theme.palette.greyscale.lightGrey}
      sx={{ verticalAlign: 'top' }}
    >
      {label}
    </Typography>
    <Typography variant="body1" color={theme.palette.greyscale.white}>
      {value}
    </Typography>
  </StyledBox>
);

export default StaticTextContainer;
