import * as Sentry from '@sentry/gatsby';
import {
  styled,
  Typography,
  useTheme,
  TypographyProps,
  Grid,
  Theme,
} from '@mui/material';
import React, { useState } from 'react';
import theme from '../theme';
import { Modal, ModalTitle } from './modal';
import Button from './button';
import Notification from './Notification';
import { Icon } from './icon';
import { useCancelOrderMutation } from '../hooks/useOrders';
import { CANCEL_ORDER_LIMIT_IN_MINUTES } from '../settings';

interface CancelOrderModalProps {
  orderId: string;
  open: boolean;
  onClose: () => void;
}

const SubHeadContainer = styled('div')`
  margin-bottom: 16px;
  @media (max-width: ${({ theme: thisTheme }: { theme: Theme }) =>
      thisTheme.breakpoints.values.sm}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const SuccessMessageContainer = styled('div')`
  margin-bottom: 184px;
`;

const CustomTypography: React.FC<
  TypographyProps & {
    children?: React.ReactNode;
    text?: string;
  }
> = ({ children, text, ...props }) => {
  const thisTheme = useTheme();
  return (
    <Typography color={thisTheme.palette.greyscale.white} {...props}>
      {children ?? text}
    </Typography>
  );
};

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  margin: '0 40px',
  paddingTop: '144px',
  paddingBottom: '24px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    paddingBottom: '8px',
  },
});

const CancelOrderModal: React.FC<CancelOrderModalProps> = ({
  open,
  onClose,
  orderId,
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showTimeExpiredMessage, setShowTimeExpiredMessage] = useState(false);
  // disables + enables input fields during info submission
  const [submitting, setSubmitting] = useState(false);

  // use gql hook
  const [cancelOrder] = useCancelOrderMutation();

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setSubmitting(true);
    event.preventDefault();

    cancelOrder({
      variables: {
        input: {
          orderId,
        },
      },
    })
      .then(({ data: responseData }) => {
        if (responseData?.cancelOrder?.order?.status === 'CANCELLED') {
          setShowSuccessMessage(true);
        } else {
          throw new Error(
            `Error trying to cancel order. Response: ${JSON.stringify(
              responseData,
            )}.`,
          );
        }
      })
      .catch((mutationError) => {
        if (
          mutationError.message.indexOf(
            'Time limit for order cancelation exceeded',
          ) > -1
        ) {
          setShowTimeExpiredMessage(true);
        } else {
          setShowErrorMessage(true);
          Sentry.captureException(mutationError);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal
      open={open}
      paperMaxWidth="628px"
      style={{
        textAlign: 'center',
      }}
      onClose={() => {
        onClose();
      }}
    >
      <div
        style={{
          paddingTop: '120px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {showSuccessMessage && (
          <>
            <Icon iconName="mail-outline" size="large" />
            <div style={{ maxWidth: '260px' }}>
              <ModalTitle>Your order has been canceled</ModalTitle>
            </div>
            <SuccessMessageContainer>
              <CustomTypography
                variant="bodyL"
                text="Check your email for a confirmation message."
              />
            </SuccessMessageContainer>
          </>
        )}

        {showTimeExpiredMessage && (
          <div style={{ maxWidth: '400px' }}>
            <ModalTitle>
              Sorry, we were unable to process your request
            </ModalTitle>
            <SuccessMessageContainer>
              <CustomTypography
                variant="bodyL"
                text="Your time to cancel your order has been expired, please contact support."
              />
            </SuccessMessageContainer>
          </div>
        )}

        {!showSuccessMessage && !showTimeExpiredMessage && (
          <>
            <ModalTitle>Cancel Order</ModalTitle>
            <SubHeadContainer>
              <CustomTypography variant="bodyL">
                Are you sure you want to cancel this order?
                <br />
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  This action cannot be undone.
                </span>
              </CustomTypography>
            </SubHeadContainer>

            <div>
              <CustomTypography
                variant="bodyXS"
                color={theme.palette.greyscale.lightGrey}
              >
                Keep in mind, orders may only be canceled within{' '}
                {CANCEL_ORDER_LIMIT_IN_MINUTES} minutes of order placement.
              </CustomTypography>
            </div>

            <ButtonContainer>
              <Button
                disabled={submitting}
                variant="primary"
                color="cyan"
                text="Yes, cancel order"
                onClick={(event) => handleOnClick(event)}
              />

              <div
                style={{
                  width: '32px',
                  height: '24px',
                }}
              />

              <Button
                disabled={submitting}
                variant="outline"
                color="cyan"
                text="Close"
                onClick={() => onClose()}
              />
            </ButtonContainer>

            {showErrorMessage && (
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '24px',
                  marginBottom: '-24px',
                  '&.MuiGrid-item': {
                    paddingTop: '0px',
                  },
                }}
              >
                <Notification
                  variant="standard"
                  severity="error"
                  sx={{
                    paddingTop: '0px',
                    color: theme.palette.error.main,
                  }}
                >
                  Error trying to cancel order. Please contact support.
                </Notification>
              </Grid>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default CancelOrderModal;
