import { Grid, Stack, styled, Typography, useTheme } from '@mui/material';
import * as React from 'react';

const CANCELED = 'CANCELED';

const ProductImage = styled('img')`
  width: 100%;
  min-width: 77.73px;
  min-height: 58.92px;
  object-fit: cover;
  padding-bottom: 0;
`;

export type OrderProductProps = {
  name: string;
  image: string;
  status: string;
};

const OrderProduct: React.FC<OrderProductProps> = ({ image, name, status }) => {
  const theme = useTheme();

  return (
    <Grid container flexDirection="row" flexWrap="nowrap" columns={12}>
      <Grid item>
        <ProductImage
          alt={name}
          src={image}
          sx={{ width: theme.spacing(10) }}
        />
      </Grid>
      <Grid item justifySelf="flex-start" sx={{ marginLeft: theme.spacing(3) }}>
        <Stack>
          <Typography
            variant="caption"
            sx={{
              color: status.toUpperCase() === CANCELED ? 'error.main' : 'success.main',
              textTransform: 'capitalize',
            }}
          >
            {status.toLowerCase()}
          </Typography>
          <Typography variant="body1">{name}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default OrderProduct;
