import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import ProfileSection from '../../components/profile/ProfileSection';
import AddressSection from '../../components/profile/AddressSection';
import GlobalGrid from '../../components/GlobalGrid';
import LoginDetails from '../../components/profile/LoginDetails';
import SecuritySection from '../../components/profile/SecuritySection';
import OrderHistory from '../../components/profile/OrderHistory';
import SEO from '../../components/seo';
import { useGlobalState } from '../../components/GlobalState';
import useGetHpidProfile from '../../hooks/useGetHpidProfile';
import PrivateRoute from '../../components/PrivateRoute';
import Layout from '../../components/layout';

const Spacer = styled('div')`
  padding: 96px 0;
`;

const Loading = styled('div')`
  padding: 2rem;
`;

const ProfilePage: React.FC = () => {
  const [{ user }, dispatch] = useGlobalState();

  // GraphQL
  const [getHpidProfile, { loading }] = useGetHpidProfile();

  // function to await the HPID profile data
  const getProfileAndUpdateState = async () => {
    if (user) {
      await getHpidProfile()
        .then(({ data }) => {
          if (user && data?.customer) {
            dispatch({
              type: 'UPDATE_USER',
              user: {
                ...user,
                profile: {
                  ...user.profile,
                  ...data.customer.hpidProfile,
                  addresses: data.customer.addresses,
                },
              },
            });
          }
        })
        .catch((updateUserError) => {
          throw new Error(updateUserError);
        });
    }
  };

  useEffect(() => {
    getProfileAndUpdateState();
  }, []);

  return (
    <PrivateRoute path="/account">
      <Layout>
        <GlobalGrid>
          <SEO title="Account Profile" description="Account Profile" />
          {loading || !user ? (
            <Loading>Loading account profile...</Loading>
          ) : (
            <>
              {!loading && user?.profile && <ProfileSection user={user} />}
              <Grid
                item
                xs={12}
                sx={{
                  '&.MuiGrid-item': {
                    paddingTop: '0px',
                  },
                }}
              >
                <Spacer />
              </Grid>
              {!loading && user?.profile?.addresses && (
                <AddressSection user={user} />
              )}
              <Grid
                item
                xs={12}
                sx={{
                  '&.MuiGrid-item': {
                    paddingTop: '0px',
                  },
                }}
              >
                <Spacer />
              </Grid>
              {!loading && user?.profile && <LoginDetails user={user} />}
              <Grid
                item
                xs={12}
                sx={{
                  '&.MuiGrid-item': {
                    paddingTop: '0px',
                  },
                }}
              >
                <Spacer />
              </Grid>
              <SecuritySection />
              <Grid
                item
                xs={12}
                sx={{
                  '&.MuiGrid-item': {
                    paddingTop: '0px',
                  },
                }}
              >
                <Spacer />
              </Grid>
              <OrderHistory />
              <Grid
                item
                xs={12}
                sx={{
                  '&.MuiGrid-item': {
                    paddingTop: '0px',
                  },
                }}
              >
                <Spacer />
              </Grid>
            </>
          )}
        </GlobalGrid>
      </Layout>
    </PrivateRoute>
  );
};

export default ProfilePage;
