const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const monthsLong = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type FormatDateConfig = {
  fullMonth: boolean;
  time: boolean;
};
const defaultConfig = { fullMonth: false, time: true };

const formatDate = (
  date: string | Date = new Date(),
  config: Partial<FormatDateConfig> = defaultConfig,
): string => {
  const dateObject = date instanceof Date ? date : new Date(date);
  const month = config.fullMonth
    ? monthsLong[dateObject.getMonth()]
    : months[dateObject.getMonth()];
  const day = dateObject.getDate();
  const year = dateObject.getUTCFullYear();

  if (!config.time) return `${month} ${day}, ${year}`;

  const time = dateObject.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return `${month} ${day}, ${year} ${time}`;
};

export default formatDate;
