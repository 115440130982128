import { gql, useMutation } from '@apollo/client';
import middlewareClient from '../apollo/middlewareClient';

const useUpdateCustomerAddress = () => {
  const UPDATE_CUSTOMER_ADDRESS = gql`
    mutation UpdateCustomerAddress($input: UpdateCustomerInput!) {
      updateCustomer(input: $input) {
        addresses {
          address1
          address2
          city
          country
          countryCodeV2
          firstName
          lastName
          province
          zip
        }
      }
    }
  `;

  return useMutation(UPDATE_CUSTOMER_ADDRESS, {
    client: middlewareClient,
  });
};

export default useUpdateCustomerAddress;
