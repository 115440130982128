import React, { useState, useEffect, useRef } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Input from '../input';
import theme from '../../theme';
import Button from '../button';
import showCloseButton from './utils';
import StaticTextContainer from './StaticTextContainer';
import { useDispatch, User } from '../GlobalState';
import useUpdateCustomerProfile from '../../hooks/useUpdateCustomerProfile';
import Notification from '../Notification';

export interface AccountProfileProps {
  user: User;
}

export const CustomHeading = styled('h3')`
  font-size: 1.5rem;
  color: ${theme.palette.greyscale.white};
  margin: 24px auto 0;
`;

export const SuccessContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const ProfileSection: React.FC<AccountProfileProps> = ({ user }) => {
  const largeScreen = useMediaQuery('(min-width:1240px)');
  const smallScreen = useMediaQuery('(min-width:600px)');
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(user?.profile);
  const [inputError, setInputError] = useState({
    firstName: false,
    lastName: false,
    userName: false,
  });
  const errorMessage = 'This field is required';
  const [disabled, setDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const userUnchanged = useRef({ currentUser });
  const [updateCustomerProfile] = useUpdateCustomerProfile();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setSubmitting(true);
    event.preventDefault();

    if (inputError.userName || inputError.firstName || inputError.lastName) {
      return;
    }
    if (user !== undefined && currentUser) {
      setDisabled(true);
      updateCustomerProfile({
        variables: {
          input: {
            updatedHpidProfile: {
              id: currentUser.id,
              countryResidence: currentUser.countryResidence,
              emails: {
                primary: currentUser.emails?.[0]?.primary,
                type: currentUser?.emails?.[0]?.type,
                value: currentUser.emails?.[0]?.value,
                verified: currentUser.emails?.[0]?.verified,
                accountRecovery: currentUser.emails?.[0]?.accountRecovery,
              },
              enabled: currentUser.enabled,
              legalZone: currentUser.legalZone,
              locale: currentUser.locale,
              name: {
                givenName: currentUser.name?.givenName || undefined,
                middleName: currentUser.name?.middleName || undefined,
                familyName: currentUser.name?.familyName || undefined,
              },
              phoneNumbers: currentUser.phoneNumbers
                ? {
                    accountRecovery:
                      currentUser.phoneNumbers[0]?.accountRecovery,
                    number: currentUser.phoneNumbers[0]?.number,
                    primary: currentUser.phoneNumbers[0]?.primary,
                    type: currentUser.phoneNumbers[0]?.type,
                    verified: currentUser.phoneNumbers[0]?.verified,
                    id: currentUser.phoneNumbers[0]?.id,
                  }
                : null,
              type: currentUser.type,
              userName: currentUser.userName,
            },
            originalHpidProfile: {
              id: user?.profile?.id,
              countryResidence: user?.profile?.countryResidence,
              emails: {
                primary: user?.profile?.emails?.[0]?.primary,
                type: user?.profile?.emails?.[0]?.type,
                value: user?.profile?.emails?.[0]?.value,
                verified: user?.profile?.emails?.[0]?.verified,
                accountRecovery: user?.profile?.emails?.[0]?.accountRecovery,
              },
              enabled: user?.profile?.enabled,
              legalZone: user?.profile?.legalZone,
              locale: user?.profile?.locale,
              name: {
                givenName: user?.profile?.name?.givenName,
                middleName: user?.profile?.name?.middleName,
                familyName: user?.profile?.name?.familyName,
              },
              phoneNumbers: user?.profile?.phoneNumbers,
              type: user?.profile?.type,
              userName: user?.profile?.userName,
            },
          },
        },
      })
        .then(() => {
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 5000);
          dispatch({
            type: 'UPDATE_USER',
            user: {
              ...user,
              profile: {
                ...currentUser,
              },
            },
          });
        })
        .catch((updateCustomerProfileError) => {
          setShowErrorMessage(true);
          setTimeout(() => {
            setShowErrorMessage(false);
          }, 5000);
          throw new Error(updateCustomerProfileError);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      navigate('/');
    }
  };

  const handleOnChange = (
    changeToMake: any,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputType?: string,
  ) => {
    if (currentUser) {
      setCurrentUser({
        ...currentUser,
        ...changeToMake,
      });
    }
    if (event === undefined || inputType === undefined) {
      return;
    }
    if (event.target.value.length === 0) {
      setInputError({ ...inputError, [inputType]: true });
    }
    if (event.target.value.length > 0) {
      setInputError({ ...inputError, [inputType]: false });
    }
  };

  const { content } = useStaticQuery(graphql`
    query ProfileUserTitles {
      content: graphCmsAccountProfile05 {
        titleUser
        ctaUser
      }
    }
  `);

  useEffect(() => {
    if (currentUser && userUnchanged.current.currentUser) {
      setDisabled(true);
    }
    if (currentUser && userUnchanged.current.currentUser !== currentUser) {
      if (inputError.userName || inputError.firstName || inputError.lastName) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [inputError, currentUser]);

  return (
    <>
      <Grid item xs={12} marginBottom={2}>
        <CustomHeading>{content?.titleUser ?? 'Profile'}</CustomHeading>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} marginBottom={1}>
        <Input
          label="First Name"
          labelColor={theme.palette.greyscale.lightGrey}
          defaultValue={currentUser?.name?.givenName}
          onChange={(event) =>
            handleOnChange(
              {
                name: {
                  ...currentUser?.name,
                  givenName: event.target.value,
                },
              },
              event,
              'firstName',
            )
          }
          error={inputError.firstName}
          helperText={inputError.firstName ? errorMessage : ''}
          showEndAdornments={showCloseButton(currentUser?.name?.givenName)}
          disabled={submitting}
        />
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} marginBottom={1}>
        <StaticTextContainer label="Country or region" value="United States" />
      </Grid>
      {largeScreen && (
        <Grid item lg={4} marginBottom={1}>
          <div />
        </Grid>
      )}
      <Grid item xs={4} sm={4} md={4} lg={4} marginBottom={1}>
        <Input
          label="Middle Name (Optional)"
          labelColor={theme.palette.greyscale.lightGrey}
          defaultValue={currentUser?.name?.middleName}
          onChange={(event) =>
            handleOnChange({
              name: {
                ...currentUser?.name,
                middleName: event.target.value,
              },
            })
          }
          sx={{
            '.MuiInputLabel-root': {
              color: '#b0b0b0',
            },
          }}
          showEndAdornments={showCloseButton(currentUser?.name?.middleName)}
          disabled={submitting}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
        lg={4}
        sx={{ paddingTop: 0 }}
        marginBottom={1}
      >
        <StaticTextContainer label="Preferred language" value="English" />
      </Grid>
      {largeScreen && (
        <Grid item lg={4} marginBottom={1}>
          <div />
        </Grid>
      )}
      <Grid item xs={4} sm={4} md={4} lg={4} marginBottom={1}>
        <Input
          label="Last Name"
          labelColor={theme.palette.greyscale.lightGrey}
          defaultValue={currentUser?.name?.familyName}
          onChange={(event) =>
            handleOnChange(
              {
                name: {
                  ...currentUser?.name,
                  familyName: event.target.value,
                },
              },
              event,
              'lastName',
            )
          }
          error={inputError.lastName}
          helperText={inputError.lastName ? errorMessage : ''}
          showEndAdornments={showCloseButton(currentUser?.name?.familyName)}
          disabled={submitting}
        />
      </Grid>
      {smallScreen && (
        <Grid item md={4} lg={8} marginBottom={1}>
          <div />
        </Grid>
      )}
      <Grid item xs={4} sm={4} md={4} lg={4} marginBottom={1}>
        <StaticTextContainer
          label="Display Name"
          value={currentUser?.userName ?? ''}
        />
      </Grid>
      {smallScreen && (
        <Grid item md={4} lg={8} marginBottom={1}>
          <div />
        </Grid>
      )}
      <Grid item xs={4} sm={4} md={4} lg={4} marginBottom={0}>
        <Button
          variant="outline"
          text={content?.ctaUser ?? 'Apply Changes'}
          onClick={(event) => handleOnClick(event)}
          disabled={disabled || submitting}
          color="cyan"
        />
      </Grid>
      {showSuccessMessage && (
        <Grid
          item
          xs={12}
          sx={{
            '&.MuiGrid-item': {
              paddingTop: '0px',
            },
          }}
        >
          <Notification
            variant="standard"
            severity="success"
            sx={{ marginTop: '1rem' }}
          >
            Your changes have successfully been applied.
          </Notification>
        </Grid>
      )}
      {showErrorMessage && (
        <Grid
          item
          xs={12}
          sx={{
            '&.MuiGrid-item': {
              paddingTop: '0px',
            },
          }}
        >
          <Notification
            variant="standard"
            severity="error"
            sx={{ marginTop: '1rem' }}
          >
            Error trying to apply changes.
          </Notification>
        </Grid>
      )}
    </>
  );
};

export default ProfileSection;
