import * as Sentry from '@sentry/gatsby';
import React, { useState, useEffect, useRef } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Select from '../select-v2';
import noNegativeNumbers from '../../utils/no-negative-numbers';
import showCloseButton from './utils';
import theme from '../../theme';
import Input from '../input';
import Button from '../button';
import { CustomHeading, AccountProfileProps } from './ProfileSection';
import RadioButton from '../RadioButton';
import Notification from '../Notification';
import { HPProfile, useDispatch } from '../GlobalState';
import useUpdateCustomerAddress from '../../hooks/useUpdateCustomerAddress';

export const StyledGrid = styled(Grid)`
  &.MuiGrid-item {
    padding-top: 0px;
  }
`;

const AddressSection: React.FC<AccountProfileProps> = ({ user }) => {
  const largeScreen = useMediaQuery('(min-width:1240px)');
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(user?.profile);
  const [error, setError] = useState({
    addressName: false,
    addressee: false,
    addressLine1: false,
    city: false,
    state: false,
    postalCode: false,
    country: false,
  });
  const errorMessage = 'This field is required';
  const [disabled, setDisabled] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const userUnchanged = useRef({ currentUser });
  const [updateCustomerAddress] = useUpdateCustomerAddress();

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setSubmitting(true);
    event.preventDefault();

    if (
      error.addressName ||
      error.addressee ||
      error.addressLine1 ||
      error.city ||
      error.state ||
      error.postalCode ||
      error.country
    ) {
      return;
    }
    if (
      user &&
      currentUser?.addresses?.length &&
      currentUser?.name?.givenName &&
      currentUser?.name?.familyName
    ) {
      setDisabled(true);
      updateCustomerAddress({
        variables: {
          input: {
            addresses: {
              address1: currentUser.addresses?.[0]?.address1 || undefined,
              address2: currentUser.addresses?.[0]?.address2 || undefined,
              city: currentUser.addresses?.[0]?.city,
              countryCode: currentUser.addresses?.[0]?.countryCodeV2,
              firstName: currentUser.name?.givenName,
              lastName: currentUser.name?.familyName,
              province: currentUser.addresses?.[0]?.province,
              zip: currentUser.addresses?.[0]?.zip,
            },
          },
        },
      })
        .then(() => {
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 5000);
          dispatch({
            type: 'UPDATE_USER',
            user: {
              ...user,
              profile: {
                ...currentUser,
              },
            },
          });
        })
        .catch((updateCustomerAddressError) => {
          setShowErrorMessage(true);
          setTimeout(() => {
            setShowErrorMessage(false);
          }, 5000);
          Sentry.captureException(updateCustomerAddressError);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      navigate('/');
    }
  };

  const handleOnChange = (
    changeToMake: Partial<HPProfile>,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputType?: string,
  ) => {
    if (currentUser) {
      setCurrentUser({
        ...currentUser,
        ...changeToMake,
      });
    }
    if (event === undefined || inputType === undefined) {
      return;
    }
    if (event.target.value.length === 0) {
      setError({ ...error, [inputType]: true });
    }
    if (event.target.value.length > 0) {
      setError({ ...error, [inputType]: false });
    }
  };

  const { content } = useStaticQuery(graphql`
    query ProfileAddressTitles {
      content: graphCmsAccountProfile05 {
        titleAddress
        ctaAddress
      }
    }
  `);

  useEffect(() => {
    if (currentUser && userUnchanged.current.currentUser) {
      setDisabled(true);
    }
    if (currentUser && userUnchanged.current.currentUser !== currentUser) {
      if (
        error.addressName ||
        error.addressee ||
        error.addressLine1 ||
        error.city ||
        error.state ||
        error.country ||
        error.postalCode
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
    if (currentUser !== undefined) {
      if (!currentUser?.addresses?.[0]?.countryCodeV2) {
        setCurrentUser({
          ...currentUser,
          addresses: [
            {
              ...currentUser.addresses?.[0],
              countryCodeV2: 'US',
            },
          ],
        });
      }
    }
  }, [error, currentUser]); // only rerun the effect when error or currentUser changes

  return (
    <>
      <StyledGrid item xs={12} marginBottom={6}>
        <CustomHeading>{content?.titleAddress ?? 'Address'}</CustomHeading>
      </StyledGrid>
      <StyledGrid item xs={4} sm={4} md={4} lg={4} marginBottom={5}>
        <Input
          label="Address line 1"
          labelColor={theme.palette.greyscale.lightGrey}
          defaultValue={
            currentUser?.addresses?.length
              ? currentUser?.addresses?.[0]?.address1
              : undefined
          }
          onChange={(event) =>
            handleOnChange(
              {
                addresses: [
                  {
                    ...currentUser?.addresses?.[0],
                    address1: event.target.value,
                  },
                ],
              },
              event,
              'addressLine1',
            )
          }
          error={error.addressLine1}
          helperText={error.addressLine1 ? errorMessage : ''}
          disabled={submitting}
          showEndAdornments={
            currentUser?.addresses?.length
              ? showCloseButton(currentUser?.addresses?.[0]?.address1)
              : false
          }
        />
      </StyledGrid>
      <StyledGrid item xs={4} sm={4} md={4} lg={4} marginBottom={5}>
        <Input
          label="Address line 2 (Optional)"
          labelColor={theme.palette.greyscale.lightGrey}
          defaultValue={
            currentUser?.addresses?.length
              ? currentUser?.addresses?.[0]?.address2
              : undefined
          }
          onChange={(event) =>
            handleOnChange({
              addresses: [
                {
                  ...currentUser?.addresses?.[0],
                  address2: event.target.value,
                },
              ],
            })
          }
          showEndAdornments={
            currentUser?.addresses?.length
              ? showCloseButton(currentUser?.addresses?.[0]?.address2)
              : false
          }
          disabled={submitting}
        />
      </StyledGrid>
      {largeScreen && (
        <StyledGrid item md={4} lg={4} marginBottom={5}>
          <div />
        </StyledGrid>
      )}
      <StyledGrid item xs={4} sm={4} md={4} lg={4} marginBottom={5}>
        <Input
          label="City"
          labelColor={theme.palette.greyscale.lightGrey}
          defaultValue={
            currentUser?.addresses?.length
              ? currentUser?.addresses?.[0]?.city
              : undefined
          }
          onChange={(event) =>
            handleOnChange(
              {
                addresses: [
                  {
                    ...currentUser?.addresses?.[0],
                    city: event.target.value,
                  },
                ],
              },
              event,
              'city',
            )
          }
          error={error.city}
          helperText={error.city ? errorMessage : ''}
          showEndAdornments={
            currentUser?.addresses?.length
              ? showCloseButton(currentUser?.addresses?.[0]?.city)
              : false
          }
          disabled={submitting}
        />
      </StyledGrid>
      <StyledGrid item xs={4} sm={4} md={4} lg={4} marginBottom={5}>
        <Input
          label="State"
          labelColor={theme.palette.greyscale.lightGrey}
          defaultValue={
            currentUser?.addresses?.length
              ? currentUser?.addresses?.[0]?.province
              : undefined
          }
          onChange={(event) =>
            handleOnChange(
              {
                addresses: [
                  {
                    ...currentUser?.addresses?.[0],
                    province: event.target.value,
                  },
                ],
              },
              event,
              'state',
            )
          }
          error={error.state}
          helperText={error.state ? errorMessage : ''}
          showEndAdornments={
            currentUser?.addresses?.length
              ? showCloseButton(currentUser?.addresses?.[0]?.province)
              : false
          }
          disabled={submitting}
        />
      </StyledGrid>
      {largeScreen && (
        <StyledGrid item lg={4} marginBottom={5}>
          <div />
        </StyledGrid>
      )}
      <StyledGrid item xs={4} sm={4} md={4} lg={4} marginBottom={5}>
        <Input
          label="Postal code"
          labelColor={theme.palette.greyscale.lightGrey}
          type="number"
          onKeyDown={(event) => noNegativeNumbers(event)}
          defaultValue={
            currentUser?.addresses?.length
              ? currentUser?.addresses?.[0]?.zip
              : undefined
          }
          onChange={(event) =>
            handleOnChange(
              {
                addresses: [
                  {
                    ...currentUser?.addresses?.[0],
                    zip: event.target.value,
                  },
                ],
              },
              event,
              'postalCode',
            )
          }
          error={error.postalCode}
          helperText={error.postalCode ? errorMessage : ''}
          showEndAdornments={
            currentUser?.addresses?.length
              ? showCloseButton(currentUser?.addresses?.[0]?.zip)
              : false
          }
          disabled={submitting}
        />
      </StyledGrid>
      <StyledGrid item xs={4} sm={4} md={4} lg={4} marginBottom={5}>
        <Select
          variant="standard"
          label="Country"
          autoWidth={false}
          multiple={false}
          native={false}
          defaultValue={
            currentUser?.addresses?.length
              ? currentUser?.addresses?.[0]?.countryCodeV2
              : 'US'
          }
          onChange={(event: SelectChangeEvent<unknown>) => {
            handleOnChange({
              addresses: [
                {
                  ...currentUser?.addresses?.[0],
                  countryCodeV2: event.target.value as string,
                },
              ],
            });
          }}
          disabled={submitting}
        >
          <Select.Option value="US">United States</Select.Option>
          <Select.Option value="CA">Canada</Select.Option>
        </Select>
      </StyledGrid>
      <StyledGrid item xs={4} sm={4} md={4} lg={12} marginBottom={6}>
        <RadioButton
          name="primary"
          value="true"
          label="Primary address"
          checked
        />
      </StyledGrid>
      <StyledGrid item xs={4} sm={4} md={4} lg={4} marginBottom={0}>
        <Button
          variant="outline"
          text={content?.ctaAddress ?? 'Apply Changes'}
          onClick={(event) => handleOnClick(event)}
          disabled={disabled || submitting}
          color="cyan"
        />
      </StyledGrid>
      {showSuccessMessage && (
        <Grid
          item
          xs={12}
          sx={{
            '&.MuiGrid-item': {
              paddingTop: '0px',
            },
          }}
        >
          <Notification
            variant="standard"
            severity="success"
            sx={{ marginTop: '1rem' }}
          >
            Your changes have successfully been applied.
          </Notification>
        </Grid>
      )}
      {showErrorMessage && (
        <Grid
          item
          xs={12}
          sx={{
            '&.MuiGrid-item': {
              paddingTop: '0px',
            },
          }}
        >
          <Notification
            variant="standard"
            severity="error"
            sx={{ marginTop: '1rem' }}
          >
            Error trying to apply changes.
          </Notification>
        </Grid>
      )}
    </>
  );
};

export default AddressSection;
