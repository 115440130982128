import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { styled, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { StyledGrid } from './AddressSection';
import { CustomHeading } from './ProfileSection';
import theme from '../../theme';
import { HPID_SECURITY_TAB_URL } from '../../settings';
import DesktopTower from './DesktopTower';
import Button from '../button';

export const ResponsiveText = styled('div')`
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const StyledStack = styled(Stack)`
  min-width: 330px;
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding-left: 4px;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
`;

const SecuritySection: React.FC = () => {
  const { content } = useStaticQuery(graphql`
    query ProfileSecurityTitles {
      content: graphCmsAccountProfile05 {
        ctaSecurity
        titleSecurity
        contentSecurity {
          raw
        }
      }
    }
  `);

  return (
    <>
      <StyledGrid item xs={12} marginBottom={2}>
        <CustomHeading>{content?.titleSecurity ?? 'Security'}</CustomHeading>
      </StyledGrid>

      {content?.contentSecurity?.raw && (
        <StyledGrid item xs={4} sm={8} md={6} lg={6} marginBottom={2}>
          <ResponsiveText>
            <Typography variant="body1" color="#b0b0b0" component="div">
              <RichText content={content?.contentSecurity?.raw} />
            </Typography>
          </ResponsiveText>
        </StyledGrid>
      )}

      <StyledGrid item xs={12}>
        <DesktopTower />
      </StyledGrid>

      <StyledGrid container direction="row" item xs={4} sm={8} md={4} lg={4}>
        <StyledStack spacing={{ xs: 0, sm: 1 }} paddingLeft={0}>
          <StyledLink href={HPID_SECURITY_TAB_URL}>
            <Button
              variant="inline"
              text={content?.ctaSecurity ?? 'Manage Sessions'}
              color="cyan"
            />
          </StyledLink>
        </StyledStack>
      </StyledGrid>
    </>
  );
};

export default SecuritySection;
