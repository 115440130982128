import * as React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import theme from '../theme';

export type RadioProps = Omit<FormControlLabelProps, 'control'> & {
  value: string;
  label?: string;
  checked?: boolean;
  name?: string;
};

const StyledRadio = styled(Radio)`
  color: #58cdff;
  & .MuiSvgIcon-root {
    font-size: 16px;
  }
  &.Mui-checked {
    color: #58cdff;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: ${theme.typography.body1.fontFamily};
  font-weight: ${theme.typography.body1.fontWeight};
  font-size: ${theme.typography.body1.fontSize};
  letter-spacing: ${theme.typography.body1.letterSpacing};
  & .MuiTypography-root {
    color: #a3a3a3;
  }
`;

const RadioButton: React.FC<RadioProps> = ({ value, label, checked, name }) => (
  <StyledFormControlLabel
    control={<StyledRadio checked={checked} name={name} value={value} />}
    label={label}
  />
);

export default RadioButton;
