import * as React from 'react';
import { Typography, TypographyProps } from '@mui/material';

const CustomCaption: React.FC<
  Omit<TypographyProps, 'children'> & { text?: string }
> = ({ children, text, ...rest }) => (
  <Typography
    variant="caption"
    sx={{ color: 'greyscale.lightGrey', pb: 0.5 }}
    {...rest}
  >
    {children ?? text}
  </Typography>
);

export default CustomCaption;
