import React, { useEffect, useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import key from 'weak-key';
import {
  Grid,
  Link,
  Stack,
  StackProps,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StringParam, useQueryParam } from 'use-query-params';
import Button from '../../button';
import { CustomHeading } from '../ProfileSection';
import { StyledGrid } from '../AddressSection';
import formatDate from '../../../utils/format-date';
import { useOrdersQuery } from '../../../hooks/useOrders';
import { Order, TransactionNode } from './types';
import AddressCard, { AddressCardProps } from './AddressCard';
import OrderProduct from './OrderProduct';
import SummaryRow from './SummaryRow';
import formatCurrency from '../../../utils/formatCurrency';
import CancelOrderModal from '../../CancelOrderModal';
import Notification from '../../Notification';
import { CANCEL_ORDER_LIMIT_IN_MINUTES } from '../../../settings';

const CANCELED = 'Canceled';

const StyledList = styled('ul')`
  margin: 0;
  list-style: none;
  padding: 0 0.5rem;
`;

export type OrderHistoryProps = {
  orderData: Order;
  number?: number;
  amount?: string;
  onClickCancel: () => void;
  shippingAddress?: Omit<AddressCardProps, 'addressTitle'>;
  billingAddress?: Omit<AddressCardProps, 'addressTitle'>;
  transaction?: TransactionNode;
  trackingUrl?: string;
  queryOrderId?: string | null;
} & StackProps;

export const PureOrderHistory: React.FC<OrderHistoryProps> = ({
  orderData,
  amount,
  billingAddress,
  shippingAddress,
  transaction,
  trackingUrl,
  queryOrderId,
  onClickCancel,
  sx,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const orderDate = formatDate(new Date(orderData.createdAt ?? new Date()), {
    fullMonth: true,
    time: false,
  });

  const items = orderData.shopifyOrder?.lineItems?.edges?.map(({ node }) => ({
    ...node,
  }));

  if (!items || items.length === 0) {
    return (
      <Typography
        variant={isMobile ? 'body2' : 'body1'}
        color="greyscale.lightGrey"
      >
        No orders found
      </Typography>
    );
  }

  useEffect(() => {
    if (
      queryOrderId &&
      orderData.id.indexOf(queryOrderId) > -1 &&
      elementRef?.current
    ) {
      elementRef.current.scrollIntoView();
    }
  }, []);

  return (
    <Stack ref={elementRef} sx={{ ml: isMobile ? 2 : 3, ...sx }} rowGap={3}>
      {items.map(({ title, fulfillmentStatus, product }) => (
        <OrderProduct
          key={key({
            title,
            product,
          })}
          name={title ?? 'Product name'}
          status={
            orderData.shopifyOrder?.cancelledAt
              ? CANCELED
              : fulfillmentStatus ?? 'Fulfillment status'
          }
          image={product?.featuredImage?.src as string}
        />
      ))}
      <Grid container>
        <Grid item sx={{ width: isMobile ? 0 : theme.spacing(13) }} />
        <Grid item xs>
          <SummaryRow
            leftTitle="Pricing"
            left={amount && formatCurrency(parseFloat(amount))}
            centerTitle="Order Placed"
            center={orderDate}
            rightTitle="Order Number"
            right={orderData?.number}
          />
          {shippingAddress && (
            <AddressCard
              addressTitle="Shipping Address"
              {...shippingAddress}
              sx={{ mt: 3 }}
            />
          )}
          <Grid
            container
            columns={12}
            rowSpacing={3}
            columnSpacing={4}
            flexDirection="row"
            sx={{ mt: 0 }}
          >
            {trackingUrl && (
              <Grid item>
                <Link href={trackingUrl}>
                  <Button variant="inline" color="cyan" text="TRACK ORDER" />
                </Link>
              </Grid>
            )}
            {new Date(orderData.createdAt).getTime() +
              CANCEL_ORDER_LIMIT_IN_MINUTES * 60000 >=
              Date.now() &&
              !orderData.shopifyOrder?.cancelledAt && (
                <Grid item>
                  <Button
                    variant="inline"
                    color="cyan"
                    onClick={onClickCancel}
                    text="CANCEL ORDER"
                  />
                </Grid>
              )}
          </Grid>
          <SummaryRow
            leftTitle="Payment Method"
            left={`${transaction?.paymentIcon?.altText ?? 'CC'} ending in ${
              transaction?.accountNumber?.slice(-4) ?? '****'
            }`}
            sx={{ mt: 0 }}
          />
          {billingAddress && (
            <AddressCard
              addressTitle="Billing Address"
              {...billingAddress}
              sx={{ mt: 3 }}
            />
          )}
          {shippingAddress?.phone && (
            <SummaryRow
              leftTitle="Phone"
              left={shippingAddress?.phone}
              sx={{ mt: 0 }}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

const OrderHistory: React.FC = () => {
  const [orderId, setOrderId] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [queryOrderId] = useQueryParam('orderId', StringParam);
  const [queryError, setQueryError] = useState(null);
  const [getOrders, { loading, data }] = useOrdersQuery(3);

  useEffect(() => {
    getOrders()
      .then(() => {
        setQueryError(null);
      })
      .catch((error) => setQueryError(error));
  }, []);

  const orderList = data?.orders
    ?.slice(0, 3)
    ?.map((order: Order, index: number) => {
      if (order.shopifyOrder && order.createdAt && order.number) {
        const {
          totalPriceSet,
          billingAddress,
          shippingAddress,
          transactions,
          fulfillments,
        } = order.shopifyOrder;

        return (
          <li key={key({ createdAt: order.createdAt, number: order.number })}>
            <PureOrderHistory
              sx={index > 0 ? { mt: 6 } : {}}
              orderData={order}
              amount={totalPriceSet?.shopMoney?.amount ?? 0}
              billingAddress={billingAddress}
              shippingAddress={shippingAddress}
              transaction={
                transactions?.length > 0 ? transactions[0] : undefined
              }
              queryOrderId={queryOrderId}
              trackingUrl={
                fulfillments.length > 0
                  ? fulfillments[0]?.trackingInfo?.url
                  : undefined
              }
              onClickCancel={() => {
                setOrderId(order.id);
                setShowModal(true);
              }}
            />
          </li>
        );
      }
      return null;
    });

  const { content } = useStaticQuery(graphql`
    query ProfileOrderHitoryTitles {
      content: graphCmsAccountProfile05 {
        titleOrderHistory
      }
    }
  `);

  return (
    <>
      <StyledGrid item xs={12} sx={{ mb: 6 }}>
        <CustomHeading>
          {content?.titleOrderHistory ?? 'Order Summary'}
        </CustomHeading>
      </StyledGrid>

      <Stack>
        {loading ? (
          <Typography sx={{ ml: 4 }}>Loading...</Typography>
        ) : (
          <StyledList>{orderList}</StyledList>
        )}

        {queryError && (
          <Notification
            variant="standard"
            severity="error"
            sx={{ paddingLeft: '2rem' }}
          >
            There was an error fetching orders
          </Notification>
        )}
      </Stack>
      <CancelOrderModal
        orderId={orderId}
        open={showModal}
        onClose={() => {
          getOrders();
          setShowModal(false);
        }}
      />
    </>
  );
};

export default OrderHistory;
