import * as React from 'react';
import { Grid, GridProps, Stack, Typography } from '@mui/material';
import CustomCaption from './CustomCaption';

export type AddressCardProps = {
  addressTitle?: string;
  address1: string;
  address2?: string | null;
  zip: string;
  city?: string;
  provinceCode?: string | null;
  country?: string;
  firstName?: string;
  lastName?: string;
  phone?: string | null;
} & GridProps;

const AddressCard: React.FC<AddressCardProps> = ({
  addressTitle,
  address1,
  address2,
  zip,
  city,
  provinceCode,
  country,
  phone,
  firstName,
  lastName,
  ...props
}) => {
  const line1 = address1 ?? `${firstName} ${lastName}`;
  const line2 = `${address2 || ''} ${city}, ${provinceCode || ''} ${zip}`;
  return (
    <Grid container {...props}>
      <Stack>
        <CustomCaption text={addressTitle} />
        <Typography variant="body2">{line1}</Typography>
        <Typography variant="body2">{line2}</Typography>
      </Stack>
    </Grid>
  );
};

export default AddressCard;
